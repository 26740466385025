// why use a route guard instead of a resolve?
// a.  we don't need a resolve because we are using a store
// b.  it gives us a benefit of controlling navigation to navigate away, etc
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';
import { LoadNotifications } from 'src/app/root-store/global-store/store/actions';
import {
  getNotificationsErred,
  getNotificationsLoaded
} from 'src/app/root-store/global-store/store/selectors';

@Injectable()
export class NotificationsGuard {
  constructor(private _guardFactory: LoadActionGuardFactoryService) {}

  canActivate(): Observable<boolean> {
    return this._guardFactory.canAdvance({
      loadAction: new LoadNotifications(),
      isLoadedSelector: getNotificationsLoaded,
      hasErrorSelector: getNotificationsErred,
      isBlocking: false // non-blocking
    });
  }
}
