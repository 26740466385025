import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { loadGrowersFromGuard } from 'src/app/root-store/global-store/store/actions/growers.actions';
import {
  getGrowersLoaded,
  getGrowersError
} from 'src/app/root-store/global-store/store/selectors/growers.selectors';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';

@Injectable()
export class GrowersLoadedGuard {
  constructor(private _guardFactoryService: LoadActionGuardFactoryService) {}

  canActivate(): Observable<boolean> {
    return this._guardFactoryService.canAdvance({
      loadAction: loadGrowersFromGuard(),
      isLoadedSelector: getGrowersLoaded,
      hasErrorSelector: getGrowersError
    });
  }
}
