// why use a route guard instead of a resolve?
// a.  we don't need a resolve because we are using a store
// b.  it gives us a benefit of controlling navigation to navigate away, etc
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import * as fromSelectors from 'src/app/root-store/global-store/store/selectors';
import * as fromReducers from 'src/app/root-store/global-store/store/reducers';
import * as fromActions from 'src/app/root-store/global-store/store/actions';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';

@Injectable()
export class AcreageOverviewGuard {
  constructor(
    private _store: Store<fromReducers.GlobalState>,
    private _guardFactory: LoadActionGuardFactoryService
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      // returns observable of true if things have gone correctly
      switchMap(() => of(true)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.waitForYearsToLoad().pipe(switchMap(() => this.waitForOverviewToLoad()));
  }

  waitForOverviewToLoad(): Observable<boolean> {
    return this._guardFactory.canAdvance({
      loadAction: new fromActions.LoadAcreageOverview(),
      isLoadedSelector: fromSelectors.getAcreageOverviewLoaded,
      hasErrorSelector: fromSelectors.getAcreageOverviewErred
    });
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForYearsToLoad(): Observable<boolean> {
    return this._store.select(fromSelectors.getProductionYearsLoaded).pipe(
      filter((loaded) => loaded),
      take(1)
    );
  }
}
