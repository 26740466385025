import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';
import * as fromGlobalStore from '../../root-store/global-store/store';

@Injectable()
export class UsersGuard {
  constructor(private _guardFactory: LoadActionGuardFactoryService) {}

  canActivate(): Observable<boolean> {
    return this._guardFactory.canAdvance({
      loadAction: fromGlobalStore.loadUsers(),
      isLoadedSelector: fromGlobalStore.getUsersLoaded,
      hasErrorSelector: fromGlobalStore.getUsersErred
    });
  }
}
