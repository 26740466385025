// why use a route guard instead of a resolve?
// a.  we don't need a resolve because we are using a store
// b.  it gives us a benefit of controlling navigation to navigate away, etc
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import * as fromActions from 'src/app/root-store/global-store/store/actions';
import * as fromReducers from 'src/app/root-store/global-store/store/reducers';
import * as fromSelectors from 'src/app/root-store/global-store/store/selectors';
import { getAppModuleDisplayName } from '../../../shared/helpers/app-modules.helper';

@Injectable()
export class AppAccessGuard {
  constructor(private _store: Store<fromReducers.GlobalState>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      // returns observable of true if things have gone correctly
      switchMap(() => of(true)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

  checkStore(): Observable<boolean> {
    return this.waitForPermissionsToLoad().pipe(switchMap(() => this.hasAppAccess()));
  }

  hasAppAccess(): Observable<boolean> {
    return this._store.select(fromSelectors.getUserPermissions).pipe(
      withLatestFrom(this._store.select(fromSelectors.getSystemInfoActiveAppModule)),
      map(([permissions, activeModule]) => {
        const hasAccess = UserService.hasAppModuleAccess(permissions, activeModule);

        if (!hasAccess) {
          const moduleName = getAppModuleDisplayName(activeModule);
          const errorMsg = `You lack access to ${moduleName}`;
          this._store.dispatch(new fromActions.RouteToHomePage(errorMsg));
          throw new Error('no access');
        }

        return hasAccess;
      }),
      take(1)
    );
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForPermissionsToLoad(): Observable<boolean> {
    return this._store.select(fromSelectors.getUserPermissionsLoaded).pipe(
      filter((loaded) => loaded),
      take(1)
    );
  }
}
