/** Modules */
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RollbarModule } from '@becksdevteam/rollbar-angular';
import config from 'src/app/app.config';
import { GlobalErrorHandler } from 'src/app/core/classes/global-error-handler.class';
import { AppDefaultsService } from 'src/app/core/services/app-defaults.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ConfirmationDialogModule } from 'src/app/library/dialogs';
import { SnackbarModule } from 'src/app/library/snackbar';
import packageInfo from '../../../package.json';
import { AuthModule } from './auth/auth.module';
import { ModuleImportOnceGuard } from './classes/module-import-once-guard';
/** Guards */
import { guards } from './guards';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
/** Services */
import { FarmServerWebSocketService } from 'src/app/core/services/farm-server-websocket.service';
import { SocketIoModule } from 'src/app/sockets/socket-io.module';
import { ApiService } from './services/api.service';
import { GlobalService } from './services/global.service';
import { GrowersService } from './services/growers.service';
import { LoadActionGuardFactoryService } from './services/load-action-guard-factory.service';
import { LotsService } from './services/lots.service';
import { NotificationsService } from './services/notifications.service';
import { ProductionYearService } from './services/production-year.service';
import { ProductsService } from './services/products.service';
import { RegionsService } from './services/regions.service';
import { UserService } from './services/user.service';

function shortUUID() {
  const firstNumber = (Math.random() * 46656) | 0;
  const secondNumber = (Math.random() * 46656) | 0;
  const firstPart = ('000' + firstNumber.toString(36)).slice(-3);
  const secondPart = ('000' + secondNumber.toString(36)).slice(-3);
  return firstPart + secondPart;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RollbarModule.forRoot({
      accessToken: config.rollbarAccessToken,
      environment: config.name,
      codeVersion: packageInfo.version,
      payload: {
        environment: config.name,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: packageInfo.version,
            // Have Rollbar guess which frames the error was
            // thrown from when the browser does not provide line
            // and column numbers.
            guess_uncaught_frames: true
          }
        }
      }
    }),
    AuthModule.forRoot({
      issuer: `${config.okta.externalOrgUrl}/oauth2/default`,
      clientId: config.okta.clientId,
      redirectUri: `${config.prodSuiteUrl}/login/callback`,
      postLogoutRedirectUri: config.prodSuiteUrl,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      responseType: ['token', 'id_token'],
      state: shortUUID(),
      tokenManager: {
        autoRenew: true
      },
      /** @see https://github.com/okta/okta-auth-js/blob/master/docs/autoRenew-notice.md */
      services: {
        autoRenew: false,
        autoRemove: false
      },
      pkce: true,
      devMode: config.name === 'local'
    }),
    SnackbarModule.forRoot(),
    ConfirmationDialogModule.forRoot(),
    SocketIoModule.forRoot({
      url: config.socket.url,
      options: {
        forceNew: true,
        transports: ['websocket'],
        path: config.socket.path,
        reconnection: true
      }
    })
  ],
  providers: [
    ApiService,
    NotificationsService,
    FarmServerWebSocketService,
    GlobalService,
    UserService,
    LotsService,
    ProductionYearService,
    ProductsService,
    RegionsService,
    UsersService,
    AppDefaultsService,
    LoadActionGuardFactoryService,
    GrowersService,
    AppDefaultsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ...guards,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule extends ModuleImportOnceGuard {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
