import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';
import { InitializeUser } from 'src/app/root-store/global-store/store/actions';
import { getUserErred, getUserLoaded } from 'src/app/root-store/global-store/store/selectors';

@Injectable()
export class UserGuard {
  constructor(
    private _authService: AuthService,
    private _guardFactory: LoadActionGuardFactoryService
  ) {}

  canActivate(): Observable<boolean> {
    return this._authService.isAuthenticated$.pipe(
      // only pass when user is authenticated
      filter((isAuthenticated) => isAuthenticated),
      // perform necessary loads
      switchMap(() => this._canAdvance())
    );
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate();
  }

  private _canAdvance(): Observable<boolean> {
    return this._guardFactory.canAdvance({
      loadAction: new InitializeUser(),
      isLoadedSelector: getUserLoaded,
      hasErrorSelector: getUserErred
    });
  }
}
